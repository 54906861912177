import React from "react"
import GlobalState from "../components/context/GlobalState"
import QuizApp from "../components/quiz/quizApp"
import PrivateRoute from "../components/context/privateRoute"
import { Router } from "@reach/router"

const PaperQuiz = props => {
  return (
    <GlobalState>
      <Router>
        <PrivateRoute
          path="/paperquiz"
          component={QuizApp}
          paperId={props?.location?.state?.paperId}
          identifier={props?.location?.state?.identifier}
          quizTime={props?.location?.state?.quizTime}
        />
      </Router>
    </GlobalState>
  )
}
export default PaperQuiz
