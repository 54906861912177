import React, { useEffect, useState } from "react"
import QuizLayout from "./quiz-layout"
import { useQuiz } from "../context/GlobalState"
import Result from "./result"
import jmespath from "jmespath"
import { navigate, Link } from "gatsby"
import _ from "lodash"
import {
  INSTRUCTION_MODE,
  RESULT_MODE,
  QUIZ_MODE,
} from "../constants/stringVariables"
import { getAllQuestions, getQuiz } from "../context/graph"
import { useQueries, useQueryClient } from "react-query"
import { useQuizStore } from "../context/quizStore"
import { useProfileStore } from "../context/profileStore"

import Loader from "../app/app-components/loader"

const QuizApp = ({ identifier, quizTime, paperId }) => {
  // console.log("identifier", paperId, identifier)
  const queryClient = useQueryClient()
  const { appMode } = useQuiz()
  const setQuiz = useQuizStore(state => state.setQuiz)
  const setQuestions = useQuizStore(state => state.setQuestions)
  const jumpIndex = useQuizStore(state => state.jumpIndex)
  const prevAttempt = useProfileStore(state => state.prevAttempt)

  const [isAllFetched, setIsAllFetched] = useState(false)
  const [hasError, setHasError] = useState(false)

  let attemptedIds = []
  const attempts = jmespath.search(prevAttempt, "[].quizId")
  attempts?.forEach(attempt => {
    let value = attempt.split(",")
    attemptedIds = [...attemptedIds, ...value]
  })

  const QuizInfo = useQueries(
    identifier?.map(section => {
      return {
        queryKey: ["section", section],
        queryFn: () => getQuiz(section, attemptedIds),
      }
    }),
    {
      refetchInterval: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      // enabled: _.isLength(identifier),
    }
  )

  const QuizTemplate = jmespath.search(QuizInfo, "[].data[]")
  const isInfoSuccess = QuizInfo.length === identifier.length

  //----------------------------------------------------------------

  const Quiz = useQueries(
    QuizTemplate?.map(section => {
      const sectionInfo = {
        quizId: section.quizId,
        sectionId: section.sectionId,
        name: section.name,
        quiz: section.quiz,
      }

      return {
        queryKey: ["get-questions", section.quizId],
        queryFn: () =>
          getAllQuestions(
            section.quiz,
            section.sectionId,
            section.quizId,
            sectionInfo
          ),
      }
    }),
    {
      refetchInterval: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      // enabled: _.isLength(identifier),
    }
  )
  const isDataLoading = Quiz.some(query => query.isLoading)
  const isDataSuccess = Quiz.every(query => query.isSuccess)
  const isDataError = Quiz.some(query => query.isError)
  const isFetched = Quiz.length === identifier.length

  useEffect(() => {
    if (isFetched && isDataSuccess) {
      const QuestionsNew = jmespath.search(Quiz, "[].data[].questions[]")
      const QuizNew = MergeQuizData(QuizTemplate, QuestionsNew)
      const Ques = jmespath.search(QuizNew, "[].questions[]")
      setQuiz(QuizNew)
      setQuestions(Ques)
      jumpIndex(0)
      // console.log("QuizInfo", QuizNew, Ques)
      if (Ques.length) {
        setIsAllFetched(true)
      }
      if (!Ques[0].question) {
        setHasError(true)
      }
    }
  }, [isDataSuccess])
  //----------------------------------------------------------------

  useEffect(() => {
    queryClient.invalidateQueries(["get-questions", identifier])
  }, [])

  if (isDataLoading) return <Loader />
  if (isDataError) return <p>Something went wrong</p>

  function MergeQuizData(QuizWithQIDs, QuizWithQuestions) {
    if (QuizWithQuestions) {
      let idx = 0
      let data = QuizWithQIDs?.map(section => {
        let value = section.quiz.map(qid => {
          let filteredQuestion = _.filter(QuizWithQuestions, [
            "questionId",
            qid,
          ])
          idx = idx + 1
          return {
            ...filteredQuestion[0],
            serial: idx,
            answered: false,
            visited: false,
            reviewed: false,
            timeTaken: null,
            responseHistory: [],
          }
        })
        return { ...section, questions: value }
      })
      return data
    }
  }

  return (
    <>
      {appMode === INSTRUCTION_MODE ? navigate("/app/instructions") : null}
      {appMode === RESULT_MODE ? <Result /> : null}
      {isDataLoading && <Loader />}
      {!hasError && isAllFetched && isFetched && appMode === QUIZ_MODE && (
        <QuizLayout quizTime={quizTime} paperId={paperId} />
      )}
      {hasError && (
        <div className="flex flex-col space-y-2 h-screen items-center justify-center">
          <p>Something Went wrong</p>
          <Link to="/app/home" className="btn">
            back Home
          </Link>
        </div>
      )}
    </>
  )
}

export default QuizApp

// useEffect(() => {
//   window.addEventListener("beforeunload", alertUser)
//   if (!identifier) {
//     navigate(`/app/home`)
//   }
//   return () => {
//     window.removeEventListener("beforeunload", () => navigate(`/app/home`))
//   }
// }, [])
// const alertUser = e => {
//   e.preventDefault()
//   e.returnValue = ""
// }

// useEffect(() => {
//   if (identifier) {
//     window.onbeforeunload = function () {
//       return true
//     }
//   }
//   return () => {
//     window.onbeforeunload = null
//     navigate("/app/home")
//   }
// }, [identifier])

// const QIDArray = jmespath.search(QuizTemplate, "[].quiz[]")
// const isDataLoading = QuizInfo.some(query => query.isLoading)
//
// const { isLoading, isError, isSuccess } = useQuery(
//   ["get-questions", identifier],
//   () => getQuestions(QIDArray),
//   {
//     enabled: QIDArray?.length > 0 && !isDataLoading,
//     // refetchInterval: false,
//     // staleTime: Infinity,
//     refetchOnWindowFocus: false,
//     onSuccess: data => {
//       console.log("data", data)
//       const QuizFinal = MergeQuizData(QuizTemplate, data)
//       const Questions = jmespath.search(QuizFinal, "[].questions[]")
//       const uniq = _.sortBy(Questions, "questionId")
//       // setQuiz(QuizFinal)
//       // setQuestions(Questions)
//       // jumpIndex(0)
//       console.log("onsuccess", QuizFinal, Questions)
//     },
//   }
// )
