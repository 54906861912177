import create from "zustand"
import { persist } from "zustand/middleware"
import { devtools } from "zustand/middleware"
import produce from "immer"

const QuizStore = set => ({
  index: 0,
  quiz: [],
  questions: [],
  setQuiz: quiz => setQuiz(set, quiz),
  setQuestions: questions => setQuestions(set, questions),
  next: timeTaken => increaseIndex(set, timeTaken),
  prev: timeTaken => decreaseIndex(set, timeTaken),
  jumpIndex: (IndexValue, timeTaken) => ChangeIndex(set, IndexValue, timeTaken),
  onResponse: (IndexValue, OptionIndex) =>
    saveResponse(set, IndexValue, OptionIndex),
  review: IndexValue => markReview(set, IndexValue),
  clearResponse: IndexValue => deleteRespose(set, IndexValue),
})

const immer = config => (set, get) => config(fn => set(produce(fn)), get)
const persistedStore = persist(devtools(immer(QuizStore)), {
  name: "Quiz-storage", // unique name
  getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
})

export const useQuizStore = create(persistedStore)
/*------- Write All logic below this-------------- */

function increaseIndex(set, timeTaken) {
  return set(state => {
    state.questions[state.index].visited = true
    const time = state.questions[state.index].timeTaken
    state.questions[state.index].timeTaken = time ? time + timeTaken : timeTaken
    // update index after all above operations only
    state.index = Math.min(state.index + 1, state.questions.length - 1)
    // if (state.index < state.questions.length - 1) {
    //   state.questions[state.index + 1].visited = true
    // }
  })
}

function decreaseIndex(set, timeTaken) {
  return set(state => {
    state.questions[state.index].visited = true
    const time = state.questions[state.index].timeTaken
    state.questions[state.index].timeTaken = time ? time + timeTaken : timeTaken
    // update index after all above operations only
    state.questions[state.index - 1].visited = true
    state.index = Math.max(state.index - 1, 0)
  })
}

function ChangeIndex(set, IndexValue, timeTaken) {
  return set(state => {
    const time = state.questions[state.index]?.timeTaken
    state.questions[state.index].timeTaken = time ? time + timeTaken : timeTaken
    // update index after all above operations only
    state.index = IndexValue
    state.questions[IndexValue].visited = true
  })
}

function setQuiz(set, quiz) {
  return set(state => {
    state.quiz = quiz
  })
}
function setQuestions(set, questions) {
  return set(state => {
    state.questions = questions
  })
}

function saveResponse(set, IndexValue, OptionIndex) {
  return set(state => {
    if (state.questions[IndexValue].ansType.type === "single_answer") {
      state.questions[IndexValue].options.forEach(x => {
        x.selected = false
      })
    }
    state.questions[IndexValue].options[OptionIndex].selected = true
    state.questions[IndexValue].answered = true
    state.questions[IndexValue].visited = true
    const prevResponse = state.questions[IndexValue].responseHistory
    state.questions[IndexValue].responseHistory = [
      ...prevResponse,
      state.questions[IndexValue].options[OptionIndex].optionId,
    ]
  })
}
function markReview(set, IndexValue) {
  return set(state => {
    state.questions[IndexValue].visited = true
    if (state.index < state.questions.length - 1) {
      state.questions[state.index + 1].visited = true
    }
    state.questions[IndexValue].review = true
    state.index = Math.min(state.index + 1, state.questions.length - 1)
  })
}
function deleteRespose(set, IndexValue) {
  return set(state => {
    state.questions[IndexValue].answered = false
    state.questions[IndexValue].review = false
    state.questions[IndexValue].options.forEach(x => {
      x.selected = false
    })
  })
}
