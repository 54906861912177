import React, { useEffect, useState } from "react"
import {
  ArrowNarrowBottomIcon,
  ArrowNarrowTopIcon,
} from "@heroicons/react/outline"

const Directions = ({ directions, CleanHtml, index }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(false)
  }, [index])

  return (
    <>
      {directions ? (
        <div>
          <div
            className={`flex flex-col  text-gray-600 border-b pb-2  select-none rounded-t-md overflow-hidden break-words`}
          >
            <p className="hidden text-sm text-gray-500">Directions:</p>
            <div className={`${isOpen ? "line-clamp-none" : "line-clamp-3"}`}>
              {CleanHtml(directions)}
            </div>
            <p
              className="text-sm font-semibold underline"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? "" : "More"}
            </p>
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="hidden w-full p-2 text-xs bg-gray-100 rounded-b-md focus:outline-none "
          >
            {isOpen ? "Less" : "More"}
          </button>
        </div>
      ) : null}
    </>
  )
}

export default Directions
