import React, { useEffect } from "react"
import QuestionCard from "./question-card"
import { useQuiz } from "../context/GlobalState"
import classNames from "classnames"
import Sidebar from "./sidebar"
import _ from "lodash"
import { RESULT_MODE } from "../constants/stringVariables"
import { SET_APP_MODE } from "../constants/actionTypes"
import { useOutside } from "../hooks/useOutside"
import { useQuizStore } from "../context/quizStore"
import QuizHeader from "./QuizHeader"
import NavigationButtons from "./NavigationButtons"
import { useStopwatch } from "react-timer-hook"

export const QuizLayout = ({ quizTime, paperId }) => {
  const isBrowser = typeof window !== "undefined"
  const { dispatch } = useQuiz()
  const quiz = useQuizStore(state => state.quiz)
  const questions = useQuizStore(state => state.questions)
  const index = useQuizStore(state => state.index)
  const jumpIndex = useQuizStore(state => state.jumpIndex)
  //const [sidebar, setSidebar] = useState(false)
  const { visible, setVisible, ref } = useOutside(false)

  const handleSidebar = () => {
    setVisible(!visible)
  }

  const { seconds, minutes, reset, pause, start } = useStopwatch({
    autoStart: true,
  })

  const timeTaken = minutes * 60 + seconds

  // useEffect(() => {
  //   if (isBrowser) {
  //     window.addEventListener("focus", start)
  //     window.addEventListener("blur", pause)
  //   }
  //   return () => {
  //     window.removeEventListener("focus", start)
  //     window.removeEventListener("blur", pause)
  //   }
  // })

  const setResultMode = () => {
    dispatch({
      type: SET_APP_MODE,
      appMode: RESULT_MODE,
    })
  }

  const sectionColor = section => {
    let start = getStartSerial(section)
    let end = getEndSerial(section)
    let currentIndex = index + 1
    let isSelected = currentIndex >= start && currentIndex <= end

    return classNames({
      "bg-white ring-1 ring-gray-400": isSelected,
      "text-gray-500": !isSelected,
    })
  }

  function getStartSerial(section) {
    return _.head(section.questions).serial
  }
  function getEndSerial(section) {
    return _.last(section.questions).serial
  }

  const SectionTabs = ({ quiz }) => {
    return (
      <div className="flex flex-row items-baseline px-4 space-x-2 ">
        <div className="text-xs font-semibold">Sections</div>
        {quiz &&
          quiz.map(section => (
            <button
              key={section.sectionId}
              id={getStartSerial(section) - 1}
              className={`flex text-xs focus:outline-none rounded py-2 px-2 ${sectionColor(
                section
              )}`}
              onClick={() => {
                jumpIndex(getStartSerial(section) - 1)
                if (index !== getStartSerial(section) - 1) {
                  reset()
                }
              }}
            >
              {section.name}
            </button>
          ))}
      </div>
    )
  }

  return (
    <div className="flex justify-center w-full h-screen overflow-hidden antialiased font-Montserrat overscroll-none ">
      <div className="relative flex flex-col justify-between flex-1 w-full bg-white ">
        <div className="flex flex-col flex-grow">
          <QuizHeader
            setResultMode={setResultMode}
            handleSidebar={handleSidebar}
            quizTime={quizTime}
            paperId={paperId}
          />
          <div className="items-center justify-start flex-none hidden p-2 mt-20 space-x-3 md:flex h-15">
            <SectionTabs quiz={quiz} />
          </div>
          <div className="flex flex-col flex-grow h-48 p-2 mt-16 mb-20 overflow-y-auto md:mt-4 md:mb-20 md:px-8 hideScrollbar overscroll-auto">
            {questions && (
              <QuestionCard time={{ minutes, seconds }} questions={questions} />
            )}
          </div>
        </div>
        <NavigationButtons resetStopwatch={reset} timeTaken={timeTaken} />
      </div>

      <div ref={ref} className="relative ">
        <Sidebar
          quiz={quiz}
          visible={visible}
          handleSidebar={handleSidebar}
          resetStopwatch={reset}
          timeTaken={timeTaken}
          paperId={paperId}
        />
      </div>
    </div>
  )
}

export default QuizLayout
