import create from "zustand"
import { persist } from "zustand/middleware"
import { devtools } from "zustand/middleware"
import produce from "immer"

const ProfileStore = set => ({
  prevAttempt: {},
  setPrevAttempt: prevAttempt => setPrevAttempt(set, prevAttempt),
  enrolledExamList: [],
  setEnrolledExamList: enrollments => setEnrolledExamList(set, enrollments),
})

const immer = config => (set, get) => config(fn => set(produce(fn)), get)
const persistedStore = persist(devtools(immer(ProfileStore)), {
  name: "profile", // unique name
})

export const useProfileStore = create(persistedStore)
/*------- Write All logic below this-------------- */

function setPrevAttempt(set, prevAttempt) {
  return set(state => {
    state.prevAttempt = prevAttempt
  })
}
function setEnrolledExamList(set, enrollments) {
  return set(state => {
    state.enrolledExamList = enrollments
  })
}
