import { useQuery, gql } from "@apollo/client"

const GetQuiz = quizIdentifier => {
  let QuizwithQID = []

  quizIdentifier?.forEach(section => {
    let sectionId = `S${section}`
    const result = ExecuteQuery(QUIZQUERY, sectionId)
    if (!result.loading) {
      QuizwithQID.push(result.data.quizstores[0])
    }
  })

  //console.log("QuizwithQID", QuizwithQID)
  return QuizwithQID
}

const ExecuteQuery = (QUERY, sectionId) => {
  const { loading, data, error } = useQuery(QUERY, {
    variables: { sectionId },
  })
  return { loading, data, error }
}

let QUIZQUERY = gql`
  query MyQuery2($sectionId: String!) {
    quizstores(
      where: { quizId_contains: $sectionId }
      limit: 1
      sort: "genDate:desc"
    ) {
      quizId
      quiz
      sectionId
    }
  }
`

export default GetQuiz
