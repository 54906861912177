import React from "react"
import SectionGrid from "./question-grid"
import _ from "lodash"
import { navigate, Link } from "gatsby"
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline"
import { useQuizStore } from "../context/quizStore"

const Sidebar = ({
  quiz,
  visible,
  handleSidebar,
  resetStopwatch,
  timeTaken,
  paperId,
}) => {
  const questions = useQuizStore(state => state.questions)
  const QuizProgress = {
    AnsweredCount: _.filter(questions, { answered: true }).length,
    ReviewCount: _.filter(questions, { review: true }).length,
    NotAnsweredCount: _.filter(questions, { answered: false }).length,
    NotVisitedCount: _.filter(questions, { visited: false }).length,
  }

  function getStartSerial(section) {
    return _.head(section.questions).serial
  }
  function getEndSerial(section) {
    return _.last(section.questions).serial
  }

  return (
    <div
      className={`absolute inset-y-0 right-0 z-50 flex flex-col justify-between flex-none h-screen transform ${
        visible ? "" : "translate-x-full"
      } bg-white shadow-sm md:relative md:translate-x-0 w-80 transition duration-200 ease-in-out`}
    >
      <div className="flex flex-col flex-auto">
        <div className="flex items-center justify-between flex-none h-20 p-4 md:justify-end">
          <button
            className="p-4 md:hidden focus:outline-none"
            onClick={handleSidebar}
          >
            <ArrowNarrowLeftIcon className="w-4" />
          </button>
          <div className="flex items-center p-4">
            <span className="pl-2 font-bold">{paperId?.name}</span>
          </div>

          <div className="flex hidden space-x-2">
            <button
              id="home"
              onClick={() => {
                navigate(`/app/home`)
              }}
              className="btn-light"
            >
              Leave
            </button>
            <button
              id="result"
              onClick={() => navigate(`/result`)}
              className="hidden btn"
            >
              Submit
            </button>
            <Link
              to={`/result`}
              state={{
                paperId: paperId,
              }}
              className="btn"
            >
              Submit
            </Link>
          </div>
        </div>

        <QuizProgresCard QuizProgress={QuizProgress} />
        <div className="flex-auto px-4 py-2 overflow-y-auto rounded hideScrollbar h-52">
          <div className="flex flex-col">
            {quiz?.map(section => (
              <SectionGrid
                key={section.sectionId}
                sectionId={section.sectionId}
                title={section.name}
                start={getStartSerial(section)}
                end={getEndSerial(section)}
                resetStopwatch={resetStopwatch}
                timeTaken={timeTaken}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-row items-center justify-center flex-none hidden p-5">
          <button
            disabled={true}
            className="flex justify-center w-full py-3 text-gray-700 border border-gray-200 rounded hover:shadow disabled:opacity-40 disabled:bg-gray-300"
          >
            Submit Section
          </button>
        </div>
      </div>
    </div>
  )
}

function QuizProgresCard({ QuizProgress }) {
  return (
    <div className="grid grid-cols-2 gap-2 p-4 px-6 ">
      <div className="flex items-center ">
        <div className="flex items-center justify-center text-xs text-green-800 bg-green-100 border border-gray-200 rounded-full opacity-70 w-7 h-7">
          {QuizProgress.AnsweredCount}
        </div>
        <p className="pl-4 text-xs">Answered</p>
      </div>
      <div className="flex items-center ">
        <div className="flex items-center justify-center text-xs text-red-800 bg-red-100 border border-gray-200 rounded-full opacity-70 w-7 h-7">
          {QuizProgress.NotAnsweredCount}
        </div>
        <p className="pl-4 text-xs">Not Answered</p>
      </div>
      <div className="flex items-center ">
        <div className="flex items-center justify-center text-xs text-purple-800 bg-purple-100 border border-gray-200 rounded-full opacity-70 w-7 h-7">
          {QuizProgress.ReviewCount}
        </div>
        <p className="pl-4 text-xs">Review</p>
      </div>
      <div className="flex items-center ">
        <div className="flex items-center justify-center text-xs text-gray-500 border border-gray-200 rounded-full w-7 h-7">
          {QuizProgress.NotVisitedCount}
        </div>
        <p className="pl-4 text-xs">Not Visited</p>
      </div>
    </div>
  )
}

export default Sidebar
