import React from "react"
import MathJax from "react-mathjax-preview"
import { useQuizStore } from "../context/quizStore"
import _ from "lodash"
import { MdRadioButtonUnchecked, MdRadioButtonChecked } from "react-icons/md"

const Option = ({ option, CleanHtml, Index, OptionIndex }) => {
  const onResponse = useQuizStore(state => state.onResponse)
  const isSelected = option.selected
  return (
    <button
      onClick={() => onResponse(Index, OptionIndex)}
      className={`${
        isSelected ? "bg-gray-200" : "hover:bg-gray-100"
      } flex flex-grow items-center p-3 mt-1 text-base transition duration-200 ease-in-out focus:outline-none  rounded-md select-none hover:transition-opacity `}
    >
      <div className="flex flex-none">
        {isSelected ? (
          <MdRadioButtonChecked className="w-5 h-5 mr-4 text-black" />
        ) : (
          <MdRadioButtonUnchecked className="w-5 h-5 mr-4 text-gray-400" />
        )}
      </div>
      <div className="flex justify-start text-left">
        {option.option && _.includes(option.option, "$$") ? (
          <MathJax math={option.option} id="mathjax" />
        ) : (
          CleanHtml(option.option)
        )}
      </div>
    </button>
  )
}

export default Option
