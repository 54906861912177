import { produce } from "immer"
import {
  NAVIGATE_QUIZ,
  ON_RESPONSE,
  SET_QUIZ,
  SET_APP_MODE,
} from "../constants/actionTypes"
import {
  INSTRUCTION_MODE,
  QUIZ_MODE,
  RESULT_MODE,
  FIRST,
  PREV,
  NEXT,
  LAST,
  REVIEW,
  CLEAR_RESPONSE,
} from "../constants/stringVariables"
import GetQuiz from "../quiz/get-quiz"

const OnMove = (id, state) => {
  //console.log("MoveIndex Ran")
  // defining index - it will be overrided by state
  let index = 0
  if (id == null) return

  if (id === FIRST) index = 0
  else if (id === PREV) index = state.pager.index - 1
  else if (id === NEXT) index = state.pager.index + 1
  else if (id === REVIEW) index = state.pager.index + 1
  else if (id === LAST) index = state.pager.count - 1
  else index = parseInt(id, 10)

  if (id === REVIEW) {
    state.Quiz[state.pager.index].review = true
    state.pager.index = index
    state.Quiz[index].visited = true
    return
  }

  if (id === CLEAR_RESPONSE) {
    state.Quiz[state.pager.index].review = false
    state.Quiz[state.pager.index].answered = false
    state.Quiz[state.pager.index].options.forEach(x => (x.selected = false))
    return
  }

  if (index >= 0 && index < state.pager.count) {
    state.Quiz[index].visited = true
    state.pager.index = index
    return
  }
}

const OnResponse = (currentQuestion, option, state) => {
  let parsedQuiz = JSON.parse(JSON.stringify(state.Quiz))
  let question = parsedQuiz.find(
    x => x.questionId === currentQuestion.questionId
  )
  if (question.ansType.type === "single_answer") {
    //check for type of ques

    question.options.forEach(x => {
      x.selected = false
    })
  }
  question.options.find(x => x.optionId === option.optionId).selected = true
  question.answered = true

  return { ...state, Quiz: parsedQuiz }
}

const SetAppMode = (appMode, state) => {
  if (appMode === INSTRUCTION_MODE) {
    state.appMode = appMode
    return
  }
  if (appMode === QUIZ_MODE) {
    state.appMode = appMode
    return
  }
  if (appMode === RESULT_MODE) {
    state.appMode = appMode
    return
  }
}

function OnQuiz(Quiz, QuizData, draftstate) {
  console.log("Quiz Reducer", Quiz)
  draftstate.Quiz = Quiz
  draftstate.QuizData = QuizData
  return
}

const QuizReducer = (draftstate, action) => {
  switch (action.type) {
    case SET_QUIZ:
      return OnQuiz(action.Quiz, action.QuizData, draftstate)
    case NAVIGATE_QUIZ:
      return OnMove(action.id, draftstate)
    case ON_RESPONSE:
      return OnResponse(action.currentQuestion, action.option, draftstate)
    case SET_APP_MODE:
      console.log("appMode Reducer called", action)
      return SetAppMode(action.appMode, draftstate)
    default:
      return draftstate
  }
}

const immerQuizReducer = produce(QuizReducer)

export default immerQuizReducer
