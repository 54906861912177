import React, { useReducer, createContext, useContext } from "react"
import immerQuizReducer from "../reducer/quiz-reducer"
import { NAVIGATE_QUIZ, ON_RESPONSE } from "../constants/actionTypes"
import { QUIZ_MODE } from "../constants/stringVariables"
// import fetchedTemplate from "../constants/template.json"
// //import fetchedpapers from "../constants/papers.json"
// import fetchedExam from "../constants/exams.json"
// import fetchedQbank from "../constants/fetchedqbank.json"
// import jmespath from "jmespath"
import _ from "lodash"

export const QuizContext = createContext()
export function useQuiz() {
  return useContext(QuizContext)
}

const GlobalState = props => {
  //const Papers = fetchedpapers ? fetchedpapers : {}
  // const Exam = fetchedExam ? fetchedExam : {}
  // const Template = fetchedTemplate ? fetchedTemplate : {}
  // const QuizSet = jmespath.search(fetchedQbank, "[].questions[]")
  // const FormatedQuiz = QuizSet ? QuizSet : []

  //console.log(FormatedQuiz)
  //console.log(_.groupBy(FormatedQuiz, "topic"))

  const quizSettings = {
    quizTimer: 0,
    showMarks: false,
    showSidebar: true,
    showSections: true,
    isQuizEnded: true,
    isShuffleQuestons: false,
    isShuffleOptions: false,
    isPauseAllowed: false,
    isAllAnswerCompulsary: false,
    isDarkMode: false,
  }

  const interactiveModeConfig = {
    showLiveAnswers: true,
    showHint: true,
    showSolution: true,
    showTheory: false,
    showSuccessRate: true,
    showInstructinsModal: true,
    allowReview: false,
    isAutoMove: false,
    currentTheme: null,
  }

  let initialState = {
    appMode: QUIZ_MODE,
    isInteractiveMode: false,
    Quiz: [],
    QuizData: [],
    userResponses: [].slice(),
    pager: {
      index: 0,
      size: 1,
      count: 0,
    },
  }

  // Quiz Settings end

  const [state, dispatch] = useReducer(immerQuizReducer, initialState)

  const Move = e => {
    let id = e.target.id //next or prev or indexNo.
    dispatch({
      type: NAVIGATE_QUIZ,
      id: id,
    })
  }

  const SaveResponse = updatedQuestions => {
    dispatch({
      type: ON_RESPONSE,
      Questions: updatedQuestions,
    })
  }

  // Context Provider below this

  const sendContextValues = {
    quizSettings,
    interactiveModeConfig,
    appMode: state.appMode,
    Quiz: state.Quiz,
    QuizData: state.QuizData,
    pager: state.pager,
    dispatch,
    Move,
    SaveResponse,
  }

  return (
    <QuizContext.Provider value={sendContextValues}>
      {props.children}
    </QuizContext.Provider>
  )
}

export default GlobalState
