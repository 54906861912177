import React from "react"
import sanitizeHtml from "sanitize-html"
import MathJax from "react-mathjax-preview"
import { useQuizStore } from "../context/quizStore"
import Directions from "./Directions"
import Option from "./Option"
import _ from "lodash"
import { VscCircleFilled } from "react-icons/vsc"
import { useAuth } from "../context/auth"

const QuestionCard = ({ time, questions }) => {
  const { currentUser } = useAuth()
  // const questions = useQuizStore(state => state.questions)
  const index = useQuizStore(state => state.index)
  const { directions = "", question, options, serial } = questions[index]
  const FilteredOptions = _.filter(
    options,
    option => option.option != null && option.option != ""
  )

  const isSpecialUser =
    currentUser?.email === "acadmi.in@gmail.com" ||
    currentUser?.email === "test@mail.com"

  function CleanHtml(input) {
    // let sanitized = sanitizeHtml(input)
    let sanitized = input
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitized,
        }}
      />
    )
  }

  return (
    <div className="flex flex-col p-5 mb-4 space-y-4 border-gray-100 md:p-0 ">
      <div className="flex items-center ">
        <div className="flex items-center justify-center w-8 h-8 text-xs text-gray-100 bg-black border border-gray-200 rounded-full">
          {parseInt(index) + 1}
        </div>

        {/* <div className="flex items-center justify-start pl-4 text-sm ">
          {time.minutes > 0 && <VscCircleFilled className="w-4 text-red-500" />}
          {time.minutes}:{time.seconds}
        </div> */}
        <QTimer time={time} />
      </div>
      {isSpecialUser && (
        <div className="flex">
          <p className="text-xs ml-2">{questions[index].questionId}</p>
          <VscCircleFilled className="w-4" />
          <p className="text-xs ml-2">{questions[index].source}</p>
        </div>
      )}
      <Directions directions={directions} index={index} CleanHtml={CleanHtml} />
      <div className="flex flex-col text-base font-semibold text-gray-700 break-words transition duration-500 ease-in-out select-none ">
        {/* {CleanHtml(question)} */}
        {question && _.includes(question, "$$") ? (
          <MathJax math={question} />
        ) : (
          CleanHtml(question)
        )}
      </div>
      <div className="flex flex-col flex-none border-t">
        {FilteredOptions?.map((option, idx) => (
          <Option
            key={option.id}
            option={option}
            CleanHtml={CleanHtml}
            Index={index}
            OptionIndex={idx}
          />
        ))}
      </div>
    </div>
  )
}

export function QTimer({ time }) {
  return (
    <div className="flex items-center justify-start pl-4 text-sm ">
      {time.minutes > 0 && <VscCircleFilled className="w-4 text-red-500" />}
      {time.minutes}:{time.seconds}
    </div>
  )
}

export default QuestionCard
