import _ from "lodash"

const MergeQuiz = (QuizWithQIDs, QuizWithQuestions) => {
  if (QuizWithQuestions) {
    let data = QuizWithQIDs?.map(section => {
      let value = section.quiz.map(qid => {
        let filteredQuestion = _.filter(QuizWithQuestions, ["questionId", qid])
        return {
          ...filteredQuestion[0],
        }
      })
      return { ...section, questions: value }
    })
    return data
  }
}

export default MergeQuiz
