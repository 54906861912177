import React from "react"
import { CubeTransparentIcon } from "@heroicons/react/outline"

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <CubeTransparentIcon className="w-8 h-8 mr-2 animate-spin" />
    </div>
  )
}

export default Loader
