// appModes
export const INSTRUCTION_MODE = "APP_MODE_INSTRUCTION"
export const QUIZ_MODE = "APP_MODE_QUIZ"
export const RESULT_MODE = "APP_MODE_RESULT"

// QuizNavigation buttons
export const FIRST = "FIRST"
export const PREV = "PREV"
export const NEXT = "NEXT"
export const LAST = "LAST"
export const REVIEW = "REVIEW"
export const CLEAR_RESPONSE = "CLEAR_RESPONSE"
