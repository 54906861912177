import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline"
import { useQuizStore } from "../context/quizStore"
import MergeQuiz from "../utils/MergeQuiz"
import jmespath from "jmespath"
import { useMutation } from "react-query"
import * as api from "../context/api"
import { nanoid } from "nanoid"
import Loader from "../utils/Loader"
import { useAuth } from "../context/auth"

export const Result = ({ paperId }) => {
  const { currentUser } = useAuth()
  const quiz = useQuizStore(state => state.quiz)
  const questions = useQuizStore(state => state.questions)
  const MergedQuiz = MergeQuiz(quiz, questions)
  const [likeColor, setLikeColor] = useState(null)
  const AttemptedQuizIds = jmespath.search(MergedQuiz, "[].quizId")

  const postArray = {
    studentId: currentUser?.email,
    responses: MergedQuiz,
    isComplete: true,
    quizId: AttemptedQuizIds.toString(),
    attemptId: nanoid(),
    paperId: paperId?.id,
    paperName: paperId?.name,
  }

  const mutation = useMutation(() => api.postResponse(postArray), {
    onSuccess: data => console.log(data),
  })

  useEffect(() => {
    mutation.mutate(postArray)
  }, [])

  if (mutation.isLoading)
    return (
      <div className="h-screen">
        <Loader />
      </div>
    )

  return (
    <div className="flex flex-col justify-between w-full h-screen p-4 overflow-hidden antialiased pb-7 font-Montserrat overscroll-none ">
      <div className="flex flex-col items-center flex-1 w-full mt-10 space-y-8">
        <p className="text-xl font-base">Quiz Complete</p>
        <CheckCircleIcon className="w-32 text-green-500" />
        <div className="flex items-center justify-center space-x-3">
          <ThumbDownIcon
            className={`w-10 p-2 bg-gray-100 rounded cursor-pointer active:bg-gray-200 ${
              likeColor === "disliked" && "bg-red-50 text-red-500"
            }`}
            onClick={() => setLikeColor("disliked")}
          />
          <p>Do you like the quiz ?</p>
          <ThumbUpIcon
            className={`w-10 p-2 bg-gray-100 rounded cursor-pointer active:bg-gray-200 ${
              likeColor === "liked" && " bg-green-50 text-green-500"
            }`}
            onClick={() => setLikeColor("liked")}
          />
        </div>
      </div>
      <button
        className="w-full py-6 text-lg btn font-base"
        onClick={() => navigate("/app/analysis")}
      >
        Analyse Performance
      </button>
      <button
        className="w-full py-6 mt-3 border-none btn-light"
        onClick={() => navigate("/app/home")}
      >
        Back to Home
      </button>
    </div>
  )
}

export default Result
