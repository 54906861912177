import React, { useEffect } from "react"
import { navigate, Link } from "gatsby"
import { MenuIcon, ClockIcon } from "@heroicons/react/outline"
import { useTimer } from "react-timer-hook"

const QuizHeader = ({ handleSidebar, quizTime, paperId }) => {
  const isBrowser = typeof window !== "undefined"
  function getSeconds(setTimer) {
    const time = new Date()
    const expiryTimestamp = time.setSeconds(time.getSeconds() + setTimer * 60)
    return expiryTimestamp
  }
  const expiryTimestamp = getSeconds(quizTime)
  const { seconds, minutes, hours, pause, resume } = useTimer({
    expiryTimestamp,
    onExpire: () => navigate(`/app/home`),
  })

  // useEffect(() => {
  //   if (isBrowser) {
  //     window.addEventListener("focus", resume)
  //     window.addEventListener("blur", pause)
  //   }
  //   return () => {
  //     if (isBrowser) {
  //       window.removeEventListener("focus", resume)
  //       window.removeEventListener("blur", pause)
  //     }
  //   }
  // })

  return (
    <div className="fixed top-0 left-0 right-0 flex flex-row items-center justify-between flex-none h-16 space-x-2 border-b md:right-80 sm:h-20 ">
      <div className="items-center hidden p-4  md:flex">
        <span className="pl-2 font-bold">{paperId?.name}</span>
      </div>
      <button
        onClick={handleSidebar}
        className="px-4 py-3 text-black bg-white border border-gray-200 rounded md:hidden focus:outline-none hover:shadow disabled:opacity-40 disabled:bg-gray-300"
      >
        <MenuIcon className="w-4" />
      </button>
      <div className="flex items-center p-4 space-x-4 ">
        <div className="flex rounded-xl ">
          <p className="flex items-center ">
            <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            <ClockIcon className="w-4 ml-2" />
          </p>
        </div>
        <div className="flex space-x-2">
          <button
            id="home"
            onClick={() => {
              navigate(`/app/home`)
            }}
            className="btn-light"
          >
            Leave
          </button>
          <button
            id="result"
            onClick={() => navigate(`/result`)}
            className="hidden btn"
          >
            Submit
          </button>
          <Link
            to={`/result`}
            state={{
              paperId: paperId,
            }}
            className="btn"
          >
            Submit
          </Link>
        </div>
      </div>
    </div>
  )
}

export default QuizHeader
