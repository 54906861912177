export const NAVIGATE_QUIZ = "NAVIGATE_QUIZ"
export const ON_RESPONSE = "SAVE_RESPONSE"
export const SET_APP_MODE = "SET_APP_MODE"
export const GET_STUDENT = "GET_STUDENT"
export const SET_ENROLLMENT = "SET_ENROLLMENT"
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION"
export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS"
export const GET_EXAMS = "GET_EXAMS"
export const GET_QUIZ_QID_ONLY = "GET_QUIZ_QID_ONLY"
export const SET_QUIZ = "SET_QUIZ"
