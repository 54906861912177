import React, { useState } from "react"
import { useQuizStore } from "../context/quizStore"
import classNames from "classnames"
import _ from "lodash"
import MergeQuiz from "../utils/MergeQuiz"
import { VscCircleFilled } from "react-icons/vsc"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"

const SectionGrid = props => {
  const index = useQuizStore(state => state.index)
  const jumpIndex = useQuizStore(state => state.jumpIndex)
  const quiz = useQuizStore(state => state.quiz)
  const questions = useQuizStore(state => state.questions)
  const [isExpanded, setIsExpanded] = useState(true)
  const result = _.range(props.start, props.end + 1)

  const MergedQuiz = MergeQuiz(quiz, questions)
  const filteredQuestion = _.filter(MergedQuiz, { sectionId: props.sectionId })
  const sectionAnsweredCount = _.filter(filteredQuestion[0].questions, {
    answered: true,
  }).length

  const itemcolor = gridIndex => {
    const isAnswered =
      questions[gridIndex]?.answered && !questions[gridIndex].review
    const isNotAnswered =
      (!questions[gridIndex]?.answered &&
        questions[gridIndex]?.visited &&
        !questions[gridIndex]?.review) ||
      gridIndex === 0
    const isMarkedReview =
      !questions[gridIndex]?.answered && questions[gridIndex]?.review
    const isAnsweredwithReview =
      questions[gridIndex]?.answered && questions[gridIndex]?.review
    const isNotVisited = !questions[gridIndex]?.visited
    const isCurrent = index.toString() === gridIndex.toString()

    return classNames({
      "bg-green-100 text-green-800 opacity-70": isAnswered,
      "bg-red-100 text-red-800 opacity-70": isNotAnswered,
      "bg-purple-100 text-purple-800 opacity-70": isMarkedReview,
      "bg-gradient-to-b from-green-100 to-purple-200 text-green-800 ":
        isAnsweredwithReview,
      "text-gray-500 ": isNotVisited,
      "ring-1 ring-black": isCurrent,
    })
  }

  return (
    <div className="flex flex-col mb-5">
      <button
        id="toggle-section"
        onClick={() => setIsExpanded(!isExpanded)}
        className="focus:outline-none"
      >
        <div
          className={`flex flex-row items-center justify-between flex-none w-full h-10 px-4 border rounded ${
            isExpanded && "bg-gray-50"
          }`}
        >
          <span className="text-xs text-left">{props.title}</span>
          <div className="flex justify-between w-3/12">
            <div className="flex items-center text-green-500 ">
              <VscCircleFilled className="w-3" />
              <p className="ml-1 text-xs">{sectionAnsweredCount}</p>
            </div>

            {isExpanded ? (
              <ChevronUpIcon className="w-4" />
            ) : (
              <ChevronDownIcon className="w-4" />
            )}
          </div>
        </div>
      </button>
      <div className={`m-auto ${isExpanded ? "" : "hidden"}`}>
        <ul className="grid grid-cols-5 gap-4 mt-4 w-60">
          {result.map((range, idx) => (
            <li key={range}>
              <div
                id={range - 1}
                className={`flex w-9 h-9 border border-gray-200 rounded-full ${itemcolor(
                  range - 1
                )}`}
              >
                <button
                  id={range - 1}
                  className="flex items-center justify-center w-full text-sm focus:outline-none "
                  onClick={e => {
                    if (index !== parseInt(e.target.id)) {
                      jumpIndex(parseInt(e.target.id), props.timeTaken)
                      props.resetStopwatch()
                    }
                  }}
                >
                  {range}
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default React.memo(SectionGrid)
