import React from "react"
import { useQuizStore } from "../context/quizStore"

const NavigationButtons = ({ resetStopwatch, timeTaken }) => {
  const next = useQuizStore(state => state.next)
  const prev = useQuizStore(state => state.prev)
  const review = useQuizStore(state => state.review)
  const clearResponse = useQuizStore(state => state.clearResponse)
  const index = useQuizStore(state => state.index)

  return (
    <div className="fixed bottom-0 left-0 right-0 flex flex-row items-center justify-between flex-none p-2 pb-5 select-none md:right-80 md:border-t sm:p-5">
      <div className="flex-row hidden space-x-3 sm:flex">
        <button onClick={() => clearResponse(index)} className="p-6 btn-light">
          Clear Response
        </button>
        <button
          onClick={() => {
            review(index)
            resetStopwatch()
          }}
          className="hidden p-6 btn-light sm:flex"
        >
          Mark Review
        </button>
      </div>
      <div className="flex justify-around mx-auto space-x-3 md:mx-0 ">
        <button
          onClick={() => {
            review(index)
            resetStopwatch()
          }}
          className="p-6 btn-light sm:hidden"
        >
          Review
        </button>
        <button
          onClick={() => {
            prev(timeTaken)
            resetStopwatch()
          }}
          className="p-6 btn-light focus:bg-none"
        >
          Previous
        </button>
        <button
          onClick={() => {
            next(timeTaken)
            resetStopwatch()
          }}
          className="px-8 py-6 btn"
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default NavigationButtons
